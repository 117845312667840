import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login"; // Adjust path based on your file structure
import ChatApp from "./pages/ChatApp"; // Replace with your ChatApp component
import { auth } from "./firebase-config";

const App = () => {
  const [user, setUser] = useState(null); // Track authenticated user
  
  // Monitor authentication state
  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  return (
    // <Router>
      <Routes>
        {/* Public Route */}
        <Route
          path="/login"
          element={!user ? <Login /> : <Navigate to="/chat" />}
        />
        {/* Private Route */}
        <Route
          path="/chat"
          element={user ? <ChatApp /> : <Navigate to="/login" />}
        />
        {/* Default Route */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    // </Router>
  );
};

export default App;
