import React, { useState, useEffect, useRef } from "react";
import { collection, addDoc, query, orderBy, onSnapshot } from "firebase/firestore";
import { db,auth } from "../firebase-config";
import ReactMarkdown from 'react-markdown';

const ChatBox = ({ selectedChatId }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null); // Ref to track the bottom of the messages list
  const inputRef = useRef(null);
  const user=auth.currentUser;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (selectedChatId) {
      const q = query(
        collection(db, "chats", selectedChatId, "messages"),
        orderBy("timestamp")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setMessages(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
      return () => unsubscribe();
    }
  }, [selectedChatId]);
  // Scroll to the latest message when the messages array changes
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Detect if the user is on a mobile device
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android|ipad|iphone|ipod/i.test(userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() === "") return;

    const userMessage = {
      text: input,
      timestamp: new Date(),
      sender: "user",
    };

    // Add the user's message to Firestore
    await addDoc(collection(db, "chats", selectedChatId, "messages"), userMessage);
    setInput("");

    // Call the backend to get the bot's response
    setLoading(true);
    try {
      const response = await fetch("https://astro-backend-493231910975.asia-southeast2.run.app/respond", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: input, jobId:'astro', userEmail:user.uid, chatId:selectedChatId}),
      });
      const data = await response.json();

      const botMessage = {
        text: data.response, // The bot's response from the backend
        timestamp: new Date(),
        sender: "bot",
      };

      // Add the bot's message to Firestore
      await addDoc(collection(db, "chats", selectedChatId, "messages"), botMessage);
    } catch (error) {
      console.error("Error fetching bot response:", error);
    } finally {
      setLoading(false);
    }
  };
  // Adjust textarea height dynamically
  const handleInputChange = (e) => {
    setInput(e.target.value);

    const textarea = inputRef.current;
    textarea.style.height = "auto"; // Reset height to calculate the scrollHeight
    textarea.style.height = `${Math.min(textarea.scrollHeight, 80)}px`; // Limit to 4 lines (approx. 80px)
  };

  // Function to handle key presses in the textarea
  const handleKeyDown = (e) => {
    if (!isMobile && e.key === "Enter" && !e.shiftKey) {
      // Prevent default action of Enter
      e.preventDefault();
      sendMessage(e); // Call the send message function
    }
  };

  return (
    <div className="chat-box">
      {/* <span className="chat-header">✨ Astrological Guidance ✨</span> */}
      <div className="messages" ref={messagesEndRef}>
        {messages.map((msg) => (
          <div key={msg.id} 
          className={`message-bubble ${msg.sender === "user" ? "user-bubble":"astrologer-bubble"}`}>
            <ReactMarkdown>
              {msg.text}
            </ReactMarkdown>
          </div>
        ))}
        {/* <div ref={messagesEndRef} /> */}
        {loading && <div className="message bot">Thinking...</div>}
      </div>
      <form onSubmit={sendMessage} className="input-container">
        <textarea
          ref={inputRef}
          rows="1"
          placeholder="Type your question/message here..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={loading}
        />
        <button type="submit"  disabled={loading}>Send</button>
      </form>
    </div>
  );
};

export default ChatBox;
