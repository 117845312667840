import React, {useState} from "react";
import { auth, db } from "../firebase-config";
import { doc, updateDoc } from "firebase/firestore";

const Modal = ({ activeSection, closeModal, setActiveSection }) => {
    const [rating, setRating] = useState(0); // Selected rating
    const [hoveredStar, setHoveredStar] = useState(0); // Hovered star
    const [feedbackText, setFeedbackText] = useState(""); // Feedback text
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false); 
    const user = auth.currentUser;
    
    const submitFeedback = async () => {
        if (rating === 0) {
          alert("Please select a rating.");
          return;
        }
    
        try {
            // await setDoc(doc(firestore, 'user_data', user.uid), saveData);
          await updateDoc(doc(db, "user_data", user.uid), {
            rating,
            feedbackText,
            submittedAt: new Date(),
          });
          setFeedbackSubmitted(true); // Show thank you message
          setTimeout(() => {
            closeModal(); // Close modal after a short delay
          }, 2000);
        } catch (error) {
          console.error("Error submitting feedback:", error);
          alert("Failed to submit feedback. Please try again.");
        }
      };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={closeModal}>
          &times;
        </button>
        <div className="modal-buttons">
          {/* <button
            className={activeSection === "profile" ? "active" : ""}
            onClick={() => setActiveSection("profile")}
          >
            Profile
          </button> */}
          {/* <button
            className={activeSection === "feedback" ? "active" : ""}
            onClick={() => setActiveSection("feedback")}
          >
            Feedback
          </button> */}
          {/* <button onClick={handleLogout}>Logout</button> */}
        </div>
        <div className="modal-body">
          {/* {activeSection === "profile" && (
            <div>
              <h3>Profile</h3>
              <form>
                <label>Name:</label>
                <input type="text" placeholder="Your Name" />
                <label>Date of Birth:</label>
                <input type="date" />
                <label>Exact Time:</label>
                <input type="time" />
                <label>Place of Birth:</label>
                <input type="text" placeholder="City" />
                <input type="text" placeholder="Country" />
              </form>
            </div>
          )} */}
          {activeSection === "feedback" && (
            <div>
                {feedbackSubmitted ? (
                <h3>Thank you for your feedback!</h3>
              ) : (
                <>
              <h3>Feedback</h3>
              <label>Rating:</label>
              <div className="stars">
                {[1, 2, 3, 4, 5].map((star) => (
                  <span 
                    key={star} 
                    className={`star ${star <= (hoveredStar || rating) ? "filled" : ""}`}
                    onMouseEnter={() => setHoveredStar(star)}
                    onMouseLeave={() => setHoveredStar(0)}
                    onClick={() => setRating(star)}
                  >
                    &#9733;
                  </span>
                ))}
              </div>
              <textarea
                    placeholder="Share your feedback here"
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                  >
                  </textarea>
                <div>
                    <button 
                        style={{
                            padding: "10px",
                            color: "#fff",
                            background: "#ff4d4f",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                        onClick={submitFeedback}>Submit Feedback</button>
                </div>
                </>
              )}
            </div>
          )}
          {/* {activeSection === "logout" && (
            <div>
              <h3>Are you sure you want to log out?</h3>
              <button onClick={handleLogout}>Confirm Logout</button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Modal;
