import React, { useState, useEffect } from "react";
import {auth} from '../firebase-config'
import PhoneInput from "react-phone-input-2";
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [message, setMessage] = useState("");

  // Initialize Recaptcha
//   useEffect(() =>
  const initializeRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth,
        "recaptcha-container",
        {
          size: "normal", // You can set this to 'normal' for a visible captcha
          callback: (response) => {
            console.log("Recaptcha verified!");
            // onSignInSubmit();
          },
          "expired-callback": () => {
            console.log("Recaptcha expired. Please complete it again.");
          },
        }
        
      );
      console.log("Recaptcha initialized successfully.");
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!phoneNumber) {
      setMessage("Please enter a valid phone number.");
      return;
    }

    try {
      initializeRecaptcha();
      const appVerifier = window.recaptchaVerifier;
      const formattedPhone = `+${phoneNumber}`; // Assuming country code for India
      const result = await signInWithPhoneNumber(auth, formattedPhone, appVerifier);
      setConfirmationResult(result);
      setMessage("OTP sent successfully!");
    } catch (error) {
      console.error("Error sending OTP:", error);
      setMessage("Error sending OTP. Please try again.");
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!otp) {
      setMessage("Please enter the OTP.");
      return;
    }

    try {
      await confirmationResult.confirm(otp);
      setMessage("Login successful!");
      // Redirect or take further actions after login
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setMessage("Invalid OTP. Please try again.");
    }
  };

  return (
    <div className="login-page">
        <span className="chat-header">✨ Astrological Guidance ✨</span>
      <h1>Login</h1>
      <form onSubmit={confirmationResult ? handleVerifyOtp : handleSendOtp}>
        {!confirmationResult ? (
          <>
            <label htmlFor="phone">Mobile Number</label>
            <PhoneInput
              country={"in"} // Default country
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
              placeholder="Enter your phone number"
              inputStyle={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
            <button type="submit">Send OTP</button>
          </>
        ) : (
          <>
            <label htmlFor="otp">Enter OTP</label>
            <input
              type="text"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              required
            />
            <button type="submit">Verify OTP</button>
          </>
        )}
        {message && <p>{message}</p>}
      </form>
      <div id="recaptcha-container"></div>  
    </div>
  );
};

export default Login;
