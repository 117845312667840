import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import ChatBox from "../components/ChatBox";
import Modal from "../components/Modal";
import "../App.css";
import {auth} from '../firebase-config'

const ChatApp = () => {
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [isSidebarVisible, setSidebarVisible] = useState(window.innerWidth > 768);
  const user = auth.currentUser;

  const [isModalOpen, setModalOpen] = useState(false);
  const [activeModalSection, setActiveModalSection] = useState("feedback"); // 'profile', 'feedback', or 'logout'

  const openModal = (section) => {
    setActiveModalSection(section);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const toggleSidebar = (force = null) => {
    if (force !== null) {
      setSidebarVisible(force);
    } else {
      setSidebarVisible(!isSidebarVisible);
    }
  };
  // const toggleSidebar = () => {
  //   // Only toggle sidebar visibility for mobile screens
  //   if (window.innerWidth <= 768) {
  //     setSidebarVisible((prev) => !prev);
  //   }
  // };

  return (
    <div className="app">
      <button className="menu-button" onClick={() => toggleSidebar()}>
        ☰
      </button>
      <Sidebar selectedChatId={selectedChatId}
        setSelectedChatId={setSelectedChatId}
        visible={isSidebarVisible}
        toggleSidebar={toggleSidebar}
        openModal={openModal}
      />
      <div className={`chat-container ${isSidebarVisible ? "sidebar-active" : ""}`}>
      {selectedChatId ? (
        <ChatBox selectedChatId={selectedChatId} />
      ) : (
        
        <div className="welcome-message">
            Select or start a new chat from the Menu!
        </div>
      )}
      </div>
      {isModalOpen && (
          <Modal 
            activeSection={activeModalSection} 
            closeModal={closeModal}
            setActiveSection={setActiveModalSection}
        />
        )}
    </div>
  );
};

export default ChatApp;