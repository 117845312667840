import React, { useState, useEffect } from "react";
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import { db, auth, signOut } from "../firebase-config";
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ selectedChatId, setSelectedChatId, visible, toggleSidebar, openModal }) => {
  const [chats, setChats] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("feedback"); // 'profile', 'feedback', or 'logout'
  const user = auth.currentUser;
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, `jobs/astro/${user.uid}`), (snapshot) => {
      setChats(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });
    return () => unsubscribe();
  }, []);

  const createNewChat = async () => {
    const docRef = await addDoc(collection(db, "chats"), { createdAt: new Date() });
    setSelectedChatId(docRef.id);
    if (window.innerWidth <= 768) {
      toggleSidebar(false); // Hide sidebar on mobile after selecting a chat
    }
    else {toggleSidebar(true); // Hide the sidebar after selecting a chat on mobile
    }
  };

  const handleLogout = () => {
      signOut(auth).then(() => {
          // Sign-out successful.
          console.log("User logged out successfully");
          navigate(`/login`)
      }).catch((error) => {
          // An error happened.
          console.error("Error logging out: ", error);
      });
  };

  return (
    <div className={`sidebar ${visible ? "visible" : "hidden"}`}>
      <button style={{marginTop:'40px',
        padding: "10px",
        cursor: "pointer",
        borderRadius: "5px",
        background: "#ffffff",
      }} onClick={createNewChat}>New Chat</button>
      <div className="chat-list">
        {chats.map((chat) => (
          <div
            key={chat.id}
            className={`chat-item ${selectedChatId === chat.id ? "active" : ""}`}
            onClick={() => {
              setSelectedChatId(chat.id);
              if (window.innerWidth <= 768) {
                toggleSidebar(false); // Hide sidebar on mobile after selecting a chat
              }
              else {toggleSidebar(true); // Hide the sidebar after selecting a chat on mobile
              }
            }}
            cursor="pointer"
          >
            Chat {chat.id.slice(-5)}
          </div>
        ))}
      </div>
      <div className="input-container" style={{marginBottom:'20px'}}>
        <button onClick={handleLogout}>Log Out</button>
        <button className="profile-button" onClick={() => openModal("feedback")}>
          Feedback
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
